import DestinationValidation from '../../validation/destination'
import getItemTypesLite from '../getMasterFiles/lite/getItemTypesLite'
import getProjectsLite from '../getMasterFiles/lite/getProjectsLite'

export default {
  mixins: [getItemTypesLite, getProjectsLite, DestinationValidation],
  data() {
    return {
      states: ['NSW', 'QLD', 'SA', 'TAS', 'VIC', 'WA', 'NT', 'ACT']
    }
  },
  computed: {
    fields() {
      return [
        {
          label: '* Name',
          prop: 'name',
          value: null,
          sm: '6',
          rules: this.nameRules
        },
        {
          label: 'Site ID',
          prop: 'number',
          value: null,
          sm: '6',
          rules: this.numberRules
        },
        {
          label: 'ABN',
          prop: 'abn',
          value: null,
          sm: '6',
          rules: this.abnRules
        },
        {
          label: 'Contact Email',
          prop: 'email',
          value: null,
          sm: '6',
          rules: this.contactEmailRules
        },
        {
          label: 'Contact Phone',
          prop: 'contactPhone',
          value: null,
          sm: '6',
          rules: this.contactPhoneRules
        },
        {
          label: `${this.$store.state.settings.settings.accountingSystem} ID`,
          prop: 'accountingContactId',
          value: null,
          sm: '6',
          rules: this.xeroIdRules
        },
        {
          label: 'Street Address 1',
          prop: 'addressStreet1',
          value: null,
          sm: '6',
          rules: this.addressRules
        },
        {
          label: 'Street Address 2',
          prop: 'addressStreet2',
          value: null,
          sm: '6',
          rules: this.addressRules
        },
        {
          label: 'City',
          prop: 'addressCity',
          value: null,
          sm: '4',
          rules: this.addressCityRules
        },
        {
          label: 'State',
          prop: 'addressState',
          value: null,
          sm: '4',
          items: this.states
        },
        {
          label: 'Post Code',
          prop: 'addressPostCode',
          value: null,
          sm: '4',
          rules: this.addressPostCodeRules
        },
        {
          label: 'Run ID',
          prop: 'runCode',
          value: null,
          sm: '6',
          rules: this.runCodeRules
        },
        {
          label: 'Details',
          prop: 'details',
          value: null,
          sm: '6',
          rules: this.detailsRules
        },
        {
          label: 'Delivery window',
          prop: 'deliveryWindow',
          value: null,
          sm: '6',
          rules: this.detailsRules,
          hint: 'eg 07:00-09:00, 12:00-14:00 - 24 hour format'
        },
        {
          label: 'Geofence Radius (meters)',
          prop: 'geofenceRadius',
          value: this.globalSettings.defaultGeoFenceRadius || 50,
          sm: '6',
          rules: this.geofenceRadiusRules,
          type: 'number'
        },
        {
          label: 'Latitude',
          prop: 'lat',
          value: null,
          sm: '6',
          rules: null,
          type: 'hidden'
        },
        {
          label: 'Longitude',
          prop: 'long',
          value: null,
          sm: '6',
          rules: null,
          type: 'hidden'
        },

        {
          label: 'Notifications',
          prop: 'sendNotifications',
          value: true,
          sm: '6',
          type: 'boolean'
        },
        {
          label: 'Tipping Docket Required',
          prop: 'tippingDocketRequired',
          value: true,
          sm: '6',
          type: 'boolean'
        },
        {
          label: 'Request',
          prop: 'request',
          value: false,
          sm: '6',
          type: 'boolean'
        },
        {
          label: `Allowed ${this.$store.state.global.pages.project.titleSingular}s`,
          prop: 'allowedProjects',
          items: this.projectsLite.filter(i => i.active),
          value: [],
          type: 'expansionCheckbox',
          panel: -1,
          icon: this.$store.state.global.pages.project.icon
        },
        {
          label: `Allowed ${this.$store.state.global.pages.itemType.titleSingular}s`,
          prop: 'allowedItemTypes',
          items: this.itemTypesLite,
          value: [],
          type: 'expansionCheckbox',
          panel: -1,
          icon: this.$store.state.global.pages.itemType.icon
        }
      ]
    }
  }
}
