<template>
  <v-hover v-slot:default="{ hover }">
    <v-card
      flat
      class="card-outlined"
      :elevation="hover ? 3 : 0"
      :class="{ 'on-hover': hover }"
    >
      <v-card-text>
        <v-row class="py-2">
          <v-col cols="3" class="d-flex justify-center px-0 pb-0 pt-4">
            <v-avatar
              :color="color"
              class="mt-3"
              :style="`border: 2px solid ${color};`"
            >
              <v-img v-if="imageUrl && isActive" :src="imageUrl"></v-img>
              <span v-else class="white--text headline">{{ letter }}</span>
            </v-avatar>
          </v-col>
          <v-col cols="9" class="pa-0">
            <v-card-title
              class="d-flex justify-space-between font-weight-regular px-1 pt-3"
              style="flex-wrap: nowrap;"
            >
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <div class="text-truncate pt-2" v-on="on">
                    {{ subject.name }}
                  </div>
                </template>
                <span>{{ subject.name }} - ID:{{ subject.id }}</span>
              </v-tooltip>
              <div style="white-space: nowrap;">
                <v-btn
                  icon
                  @click="generateQr"
                  class="mr-2"
                  v-if="masterFile === 'vehicle'"
                >
                  <v-icon color="grey">mdi-qrcode</v-icon>
                </v-btn>
                <v-btn
                  icon
                  @click="passwordDialog = true"
                  class="mr-2"
                  v-if="
                    (userAuthClass ||
                      subject.name === $store.state.user.user.name) &&
                      (masterFile === 'user' || masterFile === 'driver')
                  "
                >
                  <v-icon color="grey">mdi-lock</v-icon>
                </v-btn>
                <v-btn v-if="userAuthClass" icon @click="editDialog = true">
                  <v-icon color="grey">mdi-pencil</v-icon>
                </v-btn>
                <BasicMenu
                  icon="mdi-dots-vertical"
                  :items="menuItems"
                  v-if="menuItems.length"
                />
              </div>
            </v-card-title>
            <v-card-subtitle class="py-0 pl-1">
              {{ subtitle }}
              <span v-if="vehicleTypeTonnage">
                ({{ subject.tonnage }} tonne)
              </span>
            </v-card-subtitle>
            <v-card-subtitle class="py-0 pl-1" v-if="addressStr">
              {{ addressStr }}
            </v-card-subtitle>
            <v-card-subtitle class="py-0 pl-1">
              {{ details }}
              <span v-if="vehicleTonnage" class="pl-1">
                ({{ subject.tonnage }} tonne)
              </span>
            </v-card-subtitle>
            <v-card-subtitle v-if="subject.vehicle" class="py-0 pl-1">
              {{ subject.vehicle.vehicleName_vehicleType }}
            </v-card-subtitle>
          </v-col>
          <v-col cols="12" class="d-flex justify-space-between pb-0 px-1">
            <div>
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-btn
                    v-on="on"
                    icon
                    :href="'tel:' + subject.contactPhone"
                    v-if="subject.contactPhone"
                    class="mr-2"
                  >
                    <v-icon color="grey">mdi-phone</v-icon>
                  </v-btn>
                </template>
                <span>{{
                  `Call ${subject.name} on ${subject.contactPhone}`
                }}</span>
              </v-tooltip>
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-btn
                    v-on="on"
                    icon
                    :href="'mailto:' + subject.email"
                    v-if="subject.email"
                  >
                    <v-icon color="grey">mdi-email</v-icon>
                  </v-btn>
                </template>
                <span>{{ `Send email to ${subject.email}` }}</span>
              </v-tooltip>
            </div>
            <v-btn v-if="userAuthClass" icon @click="deleteDialog = true">
              <v-icon color="red lighten-3">mdi-delete</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
      <v-divider class="mx-5"></v-divider>
      <v-card-text class="d-flex align-center justify-space-between">
        <TimeAgo
          v-if="subject.updatedAt"
          class="grey--text"
          :subject="subject"
          :time="subject.updatedAt"
          needsParse
          updated
        >
          <v-icon small color="grey" slot="icon" class="mr-2">
            mdi-clock-outline
          </v-icon>
        </TimeAgo>
        <span v-if="subject.runCode && $vuetify.breakpoint.mdAndUp">
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <span v-on="on" class="px-2">
                <v-icon color="grey">mdi-run-fast</v-icon>
                {{ subject.runCode }}
              </span>
            </template>
            <span>Run ID: {{ subject.runCode }}</span>
          </v-tooltip>
        </span>
      </v-card-text>

      <v-dialog
        v-model="editDialog"
        width="700"
        @click:outside="() => (editDialog = false)"
        @keydown.esc="() => (editDialog = false)"
      >
        <MasterFileForm
          v-if="editDialog"
          :masterFile="masterFile"
          :dialog="editDialog"
          :fields="fields"
          :subject="subject"
          :onSubmit="onEdit"
          :onValidateLogin="onValidateLogin"
          :loading="loading"
          :close="() => (editDialog = false)"
        />
      </v-dialog>

      <v-dialog
        v-model="deleteDialog"
        @click:outside="() => (deleteDialog = false)"
        @keydown.esc="() => (deleteDialog = false)"
        max-width="500px"
      >
        <DeleteDialog
          :dialog="deleteDialog"
          :page="masterFile"
          :subject="subject"
          :action="onDelete"
          :close="() => (deleteDialog = false)"
        />
      </v-dialog>

      <v-dialog
        v-model="passwordDialog"
        @click:outside="() => (passwordDialog = false)"
        @keydown.esc="() => (passwordDialog = false)"
        max-width="500px"
        v-if="onUpdatePassword"
      >
        <PasswordDialog
          :dialog="passwordDialog"
          :page="masterFile"
          :subject="subject"
          :loading="loading"
          :action="onUpdatePassword"
          :close="() => (passwordDialog = false)"
        />
      </v-dialog>
    </v-card>
  </v-hover>
</template>

<script>
import MasterFileForm from './MasterFileForm'
import BasicMenu from '../misc/BasicMenu'
import DeleteDialog from '../misc/DeleteDialog'
import PasswordDialog from '../misc/PasswordDialog'
import TimeAgo from '../misc/TimeAgo'
import Auth from '../../services/authentication'
import { getVehicleQrCode } from '@/services/requests/vehicles'

export default {
  async mounted() {
    await this.getUrl()
  },
  components: {
    MasterFileForm,
    BasicMenu,
    DeleteDialog,
    PasswordDialog,
    TimeAgo
  },
  props: {
    masterFile: {
      type: String,
      required: true
    },
    subject: {
      type: Object,
      required: true
    },
    icon: String,
    fields: {
      type: Array,
      required: true
    },
    loading: {
      type: Boolean,
      required: true
    },
    onEdit: {
      type: Function,
      required: true
    },
    onUpdatePassword: {
      type: Function,
      required: false
    },
    onValidateLogin: {
      type: Function,
      required: false
    },
    onDelete: {
      type: Function,
      required: true
    }
  },
  data() {
    return {
      editDialog: false,
      deleteDialog: false,
      passwordDialog: false,
      imageUrl: null,
      fieldNames: {
        contractor: {
          subtitle: 'abn',
          details: 'email'
        },
        vehicle: {
          subtitle: 'contractor.name',
          details: 'vehicleType.name'
        },
        vehicleType: {
          subtitle: 'class'
        },
        driver: {
          subtitle: 'contractor.name'
        },
        source: {
          subtitle: 'project.name',
          details: 'email'
        },
        destination: {
          subtitle: 'number',
          details: 'details'
        },
        itemType: {
          subtitle: 'type'
        },
        user: {
          subtitle: 'userAuthClass.name',
          details: 'email'
        },
        project: {},
        userAuthClass: {}
      },
      contractorMenuItems: [
        {
          icon: this.$store.state.global.pages.vehicle.icon,
          text: 'View Vehicles',
          routeAction: { name: 'vehicle', query: { fid: this.subject.id } }
        },
        {
          icon: this.$store.state.global.pages.driver.icon,
          text: 'View Drivers',
          routeAction: { name: 'driver', query: { fid: this.subject.id } }
        }
      ],
      projectMenuItems: [
        {
          icon: this.$store.state.global.pages.source.icon,
          text: 'View Sites',
          routeAction: {
            name: this.$store.state.global.pages.source.route,
            query: { fid: this.subject.id }
          }
        }
      ]
    }
  },
  computed: {
    addressStr() {
      if (this.subject.addressStreet1)
        return `${this.subject.addressStreet1} ${this.subject.addressCity} `
      return null
    },
    imageKey() {
      return this.subject.imageKey
    },
    subtitle() {
      const subtitle = this.fieldNames[this.masterFile].subtitle
      if (subtitle) {
        return subtitle.split('.').reduce((o, i) => o[i], this.subject)
      }
      return null
    },
    details() {
      const details = this.fieldNames[this.masterFile].details
      if (details) {
        return details.split('.').reduce((o, i) => o[i], this.subject)
      }
      return null
    },
    vehicleTonnage() {
      return (
        this.masterFile === 'vehicle' &&
        this.subject.tonnage &&
        this.subject.vehicleType
      )
    },
    vehicleTypeTonnage() {
      return this.masterFile === 'vehicleType' && this.subject.tonnage
    },
    letter() {
      return this.subject.name.charAt(0)
    },
    isActive() {
      let active = true
      let activeParent = true
      if (this.subject?.projectId) activeParent = this.subject.project.active
      if (this.subject?.contractorId)
        activeParent = this.subject.contractor.active
      if (
        !activeParent ||
        (typeof this.subject.active !== 'undefined' && !this.subject.active)
      )
        active = false
      return active
    },
    color() {
      if (!this.isActive) return 'grey'
      if (this.subject.request) return 'primary lighten-1'
      if (this.subject.color) return this.subject.color
      return this.getColorFromChar(this.subject.name.charCodeAt(0))
    },
    menuItems() {
      const complianceItems = ['contractor', 'vehicle', 'driver']
      let items = []
      if (this.masterFile === 'contractor') {
        items = [
          {
            icon: this.$store.state.global.pages.vehicle.icon,
            text: 'View Vehicles',
            routeAction: { name: 'vehicle', query: { fid: this.subject.id } }
          },
          {
            icon: this.$store.state.global.pages.driver.icon,
            text: 'View Drivers',
            routeAction: { name: 'driver', query: { fid: this.subject.id } }
          }
        ]
      } else if (this.masterFile === 'project') {
        items = [
          {
            icon: this.$store.state.global.pages.source.icon,
            text: 'View Sites',
            routeAction: {
              name: this.$store.state.global.pages.source.route,
              query: { fid: this.subject.id }
            }
          }
        ]
      }
      // items.push({
      //   icon: 'mdi-file-document-plus-outline',
      //   text: 'Docs',
      //   routeAction: {
      //     name: this.$store.state.global.pages.source.route,
      //     query: { fid: this.subject.id }
      //   }
      // })
      if (complianceItems.includes(this.masterFile))
        items.push({
          icon: this.$store.state.global.pages.compliance.icon,
          text: 'Compliance',
          routeAction: {
            name: 'compliance',
            query: {
              id: this.subject.id,
              model: this.masterFile
            }
          }
        })
      items.push({
        icon: this.$store.state.global.pages.audit.icon,
        text: 'Audit',
        routeAction: {
          name: 'audit',
          query: {
            id: this.subject.id,
            model: this.masterFile
          }
        }
      })
      return items
    }
  },
  watch: {
    imageKey() {
      this.getUrl()
    }
  },
  methods: {
    getColorFromChar(code) {
      var color = 'blue'
      if (code <= 35) {
        color = 'orange'
      } else if (code <= 66) {
        color = 'teal'
      } else if (code <= 71) {
        color = 'pink darken-3'
      } else if (code <= 76) {
        color = 'purple'
      } else if (code <= 86) {
        color = 'yellow darken-3'
      } else if (code <= 96) {
        color = 'red'
      } else if (code <= 104) {
        color = 'blue-grey'
      } else if (code <= 116) {
        color = 'blue'
      }
      return color
    },
    async getUrl() {
      if (this.subject.imageKey) {
        try {
          const result = await Auth.getS3Url({
            key: this.subject.imageKey,
            bucket: 'payloader-masterfile-images'
          })
          this.imageUrl = result.data.result
        } catch (err) {
          console.log(err)
        }
      }
    },
    async generateQr() {
      const result = await getVehicleQrCode(this.subject.id)
      if (!result) return
      // this.url = result.data.result
      const image = new Image()
      image.src = result.data.result
      const w = window.open('')
      w.document.write(
        `<div style="margin-left: 25px; margin-top: 20px;">
          <h2 style="font-family: sans-serif">${this.subject.name} (ID: ${this.subject.id})</h2>
          <h3 style="font-family: sans-serif">${this.subject.contractor.name} - ${this.subject.vehicleType.name}</h3>
        </div>
          `
      )
      w.document.write(image.outerHTML)
      w.document.write(`<h1 style="font-family: sans-serif">
              <img
                src="https://console.payloader.com.au/img/payloader-large1.48462774.png"
                style="height: 50px; width: 50px; object-fit: contain; vertical-align: middle;"
              />
            payloader.com.au</h1>`)
    }
  }
}
</script>
