<template>
  <div>
    <v-app-bar app color="background" flat>
      <v-app-bar-nav-icon
        v-if="$vuetify.breakpoint.xsOnly"
        @click.stop="drawer = !drawer"
      ></v-app-bar-nav-icon>
      <v-toolbar-title>
        <span>
          Payloader
          <span class="subtitle-1" v-if="title">
            {{ `| ${title}` }}
          </span>
        </span>
      </v-toolbar-title>
    </v-app-bar>

    <v-alert
      v-if="showExpiryWarning"
      dismissible
      icon="mdi-alert-circle-outline"
      title="Alert title"
      :type="daysToLicenceExpiry <= 2 ? 'error' : 'warning'"
      class="mx-3 mb-2"
    >
      <span v-if="daysToLicenceExpiry <= 2">URGENT!</span>
      Your account is on hold. Functionality will be affected in
      {{ daysToLicenceExpiry }} day{{ daysToLicenceExpiry === 1 ? '' : 's' }}
    </v-alert>

    <v-navigation-drawer
      :stateless="!$vuetify.breakpoint.xsOnly"
      v-model="drawer"
      :permanent="!$vuetify.breakpoint.xsOnly"
      app
      width="90"
    >
      <SideDrawer :loading="loading" />
    </v-navigation-drawer>

    <transition name="fadeBackEnd" mode="out-in">
      <router-view></router-view>
    </transition>
  </div>
</template>

<script>
import UserSettings from '../services/mixins/userSettings'
import GetSettings from '../services/mixins/getSettings'
import SetPages from '../services/mixins/setPages'
import SideDrawer from '../components/misc/sideDrawer/SideDrawer'
export default {
  name: 'BackEnd',
  mixins: [UserSettings, GetSettings, SetPages],
  components: { SideDrawer },
  data() {
    return {
      drawer: false,
      loading: true,
      title: process.env.VUE_APP_TITLE || 'Sandbox'
    }
  },
  async mounted() {
    this.loading = true
    const { useApprovedBilling } = await this.getSettings()
    this.setMainPages(useApprovedBilling)
    this.setMasterFilePages()
    this.setSettingsPages()
    this.loading = false
  }
}
</script>

<style>
.fadeBackEnd-enter-active,
.fadeBackEnd-leave-active {
  transition: opacity 0.2s;
}
.fadeBackEnd-enter,
.fadeBackEnd-leave-to {
  opacity: 0;
}
.v-data-table {
  white-space: nowrap;
}
</style>
