import ProjectValidation from '../../validation/project'
import getDestinationsLite from '../getMasterFiles/lite/getDestinationsLite'
import getContractorsLite from '../getMasterFiles/lite/getContractorsLite'
import getDriversLite from '../getMasterFiles/lite/getDriversLite'
import getVehiclesLite from '../getMasterFiles/lite/getVehiclesLite'
import Store from '@/store/computed/storeHelpers'

export default {
  mixins: [
    getDestinationsLite,
    getContractorsLite,
    getDriversLite,
    getVehiclesLite,
    ProjectValidation
  ],
  data() {
    return {
      states: ['NSW', 'QLD', 'SA', 'TAS', 'VIC', 'WA', 'NT', 'ACT']
    }
  },
  computed: {
    settings: Store.getSet({ store: 'settings', prop: 'settings' }),
    fields() {
      return [
        {
          label: '* Name',
          prop: 'name',
          value: null,
          sm: '6',
          rules: this.nameRules
        },
        {
          label: 'ABN',
          prop: 'abn',
          value: null,
          sm: '6',
          rules: this.abnRules
        },
        {
          label: 'Contact Email',
          prop: 'email',
          value: null,
          sm: '6',
          rules: this.contactEmailRules
        },
        {
          label: 'Contact Phone',
          prop: 'contactPhone',
          value: null,
          sm: '6',
          rules: this.contactPhoneRules
        },
        {
          label: `${this.$store.state.settings.settings.accountingSystem} ID`,
          prop: 'accountingContactId',
          value: null,
          sm: '6',
          rules: this.xeroIdRules
        },
        {
          label: 'Street Address 1',
          prop: 'addressStreet1',
          value: null,
          sm: '6',
          rules: this.addressRules
        },
        {
          label: 'Street Address 2',
          prop: 'addressStreet2',
          value: null,
          sm: '6',
          rules: this.addressRules
        },
        {
          label: 'City',
          prop: 'addressCity',
          value: null,
          sm: '4',
          rules: this.addressCityRules
        },
        {
          label: 'State',
          prop: 'addressState',
          value: null,
          sm: '4',
          items: this.states
        },
        {
          label: 'Post Code',
          prop: 'addressPostCode',
          value: null,
          sm: '4',
          rules: this.addressPostCodeRules
        },
        {
          label: `* Match ${this.$store.state.global.pages.allocation.titleSingular} by`,
          prop: 'allocationMatchType',
          value: 'PROJECT',
          sm: '6',
          rules: this.allocationMatchType,
          itemsWithLabel: [
            {
              text: this.$store.state.global.pages.project.titleSingular,
              id: 'PROJECT'
            },
            {
              text: this.$store.state.global.pages.source.titleSingular,
              id: 'SOURCE'
            }
          ]
        },
        {
          label: `* ${this.$store.state.global.pages.bill.titleSingular} by`,
          prop: 'billType',
          value: 'DIRECT',
          sm: '6',
          rules: this.allocationMatchType,
          disabled: !this.settings.useApprovedBilling,
          itemsWithLabel: [
            {
              text: 'Use direct billing',
              id: 'DIRECT'
            },
            {
              text: 'Use approved billing',
              id: 'APPROVED'
            }
          ]
        },
        {
          label: `Allowed ${this.$store.state.global.pages.destination.titleSingular}s`,
          prop: 'allowedDestinations',
          items: this.destinationsLite,
          value: [],
          type: 'expansionCheckbox',
          panel: -1,
          icon: this.$store.state.global.pages.destination.icon
        },
        {
          label: `${this.$store.state.global.pages.contractor.titleSingular} inductions`,
          prop: 'allowedContractors',
          items: this.contractorsLite.filter(i => i.active),
          value: [],
          type: 'expansionCheckbox',
          panel: -1,
          icon: this.$store.state.global.pages.contractor.icon
        },
        {
          label: `${this.$store.state.global.pages.driver.titleSingular} inductions`,
          prop: 'allowedDrivers',
          items: this.driversLite.filter(i => i.active && i.contractor.active),
          value: [],
          nameValue: 'driverName_contractorName',
          type: 'expansionCheckbox',
          panel: -1,
          icon: this.$store.state.global.pages.driver.icon
        },
        {
          label: `${this.$store.state.global.pages.vehicle.titleSingular} inductions`,
          prop: 'allowedVehicles',
          items: this.vehiclesLite.filter(i => i.active && i.contractor.active),
          value: [],
          nameValue: 'vehicleName_contractorName_vehicleType',
          type: 'expansionCheckbox',
          panel: -1,
          icon: this.$store.state.global.pages.vehicle.icon
        },
        {
          label: 'Active',
          prop: 'active',
          value: true,
          sm: '6',
          type: 'boolean'
        }
      ]
    }
  }
}
