import VehicleValidation from '../../validation/vehicle'
import getContractorsLite from '../getMasterFiles/lite/getContractorsLite'
import getVehicleTypes from '../getMasterFiles/getVehicleTypes'
import getProjectsLite from '../getMasterFiles/lite/getProjectsLite'
export default {
  mixins: [
    VehicleValidation,
    getContractorsLite,
    getVehicleTypes,
    getProjectsLite
  ],
  computed: {
    fields() {
      return [
        {
          label: '* Registration',
          prop: 'name',
          value: null,
          sm: '6',
          rules: this.nameRules
        },
        {
          label: `* ${this.$store.state.global.pages.vehicleType.titleSingular}`,
          prop: 'vehicleTypeId',
          value: null,
          sm: '6',
          rules: this.vehicleTypeRules,
          items: this.vehicleTypes
        },
        {
          label: `* ${this.$store.state.global.pages.contractor.titleSingular}`,
          prop: 'contractorId',
          value: null,
          sm: '6',
          rules: this.contractorRules,
          items: this.contractorsLite
        },
        {
          label: 'Tonnage',
          prop: 'tonnage',
          type: 'number',
          value: 0,
          sm: '6',
          rules: this.tonnageRules
        },
        {
          label: 'Capacity (m3)',
          prop: 'capacity',
          type: 'number',
          value: 0,
          sm: '6'
        },
        {
          label: 'Details',
          prop: 'details',
          value: null,
          sm: '6',
          rules: this.detailsRules
        },
        {
          label: 'Active',
          prop: 'active',
          value: true,
          sm: '6',
          type: 'boolean'
        },
        {
          label: 'Request',
          prop: 'request',
          value: false,
          sm: '6',
          type: 'boolean'
        },
        {
          label: `${this.$store.state.global.pages.project.titleSingular} inductions`,
          prop: 'allowedProjects',
          items: this.projectsLite.filter(i => i.active),
          value: [],
          type: 'expansionCheckbox',
          panel: -1,
          icon: this.$store.state.global.pages.project.icon
        }
      ]
    }
  }
}
