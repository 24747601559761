<template>
  <v-card>
    <v-toolbar dark color="primary">
      <v-btn icon dark @click="close">
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-toolbar-title>
        <span>
          Edit {{ titleSingular }} -
          <span class="pl-1 subtitle-1">
            ID: <span class="font-weight-bold">{{ item.id }}</span>
          </span>
        </span>
      </v-toolbar-title>
      <v-spacer></v-spacer>
    </v-toolbar>
    <v-container fluid>
      <v-row>
        <v-col cols="12" class="pb-0">
          <v-sheet
            outlined
            :color="editedItem.type == 'CREDIT' ? 'red lighten-3' : ''"
            rounded
          >
            <v-card flat class="card-outlined">
              <v-form @submit.prevent="submit()" ref="form">
                <v-card-text class="pb-0">
                  <CardHeader
                    v-if="item.subject"
                    :title="item.subject.name"
                    :subtitle="
                      $store.state.global.pages[item.billedBy.toLowerCase()]
                        .titleSingular
                    "
                    :icon="$store.state.global.pages.bill.icon"
                  />
                  <v-row>
                    <v-col cols="12" sm="6" md="3">
                      <v-select
                        label="Billing Period"
                        v-model="editedItem.billingPeriod"
                        prepend-icon="mdi-calendar-blank"
                        :items="$store.state.billingPeriods.allBillingPeriods"
                        type="text"
                      ></v-select>
                    </v-col>
                    <v-col cols="12" sm="6" md="3">
                      <DatePickerWrapper
                        v-model="editedItem.date"
                        label="Date"
                        color="indigo"
                      ></DatePickerWrapper>
                    </v-col>
                    <v-col cols="12" sm="6" md="3">
                      <v-select
                        label="Type"
                        v-model="editedItem.type"
                        :prepend-icon="typeIcon"
                        :items="typeItems"
                        type="text"
                      ></v-select>
                    </v-col>
                    <v-col cols="12" sm="6" md="3">
                      <v-select
                        label="Status"
                        v-model="editedItem.status"
                        prepend-icon="mdi-information"
                        :items="statusItems"
                        type="text"
                      ></v-select>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-text-field
                        label="Reference No."
                        v-model="editedItem.externalRef"
                        prepend-icon="mdi-note"
                        type="text"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-text-field
                        :label="`${globalSettings.accountingSystem} Reference`"
                        disabled
                        v-model="editedItem.transactionId"
                        prepend-icon="mdi-note"
                        type="text"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-text-field
                        label="Internal Notes"
                        v-model="editedItem.internalNotes"
                        prepend-icon="mdi-note"
                        type="text"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-text-field
                        label="Invoice Notes"
                        v-model="editedItem.invoiceNotes"
                        prepend-icon="mdi-note"
                        type="text"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn text color="grey darken-1" @click="cancel">
                    Cancel
                  </v-btn>
                  <v-btn text color="primary" type="submit" :loading="loading">
                    Update
                  </v-btn>
                </v-card-actions>
              </v-form>
            </v-card>
          </v-sheet>
        </v-col>
        <v-col cols="12">
          <LineView
            :headers="lineHeaders"
            @showAddLineDialog="addLineDialog = !addLineDialog"
            :items="lines"
            :onEdit="onEdit"
            :onDelete="onDelete"
            :loading="loading"
            :loadingLines="loadingLines"
            showEdit
            showAddBtn
            hideDetails
          />
          <div v-if="sellTotalForLines" class="mt-3 text-right title">
            Total: ${{ addCommas(sellTotalForLines.toFixed(2)) }}
          </div>
        </v-col>
      </v-row>
    </v-container>
    <v-dialog
      v-model="addLineDialog"
      @click:outside="() => (addLineDialog = false)"
      @keydown.esc="() => (addLineDialog = false)"
      max-width="900px"
    >
      <LineAdd
        :dialog="addLineDialog"
        :action="addLine"
        :item="item"
        :lines="lines"
        :loading="loadingLines"
        :onAdd="addLine"
        :close="() => (addLineDialog = false)"
      />
    </v-dialog>
  </v-card>
</template>

<script>
import Vue from 'vue'
import {
  getLinesByParentId,
  addBillLine,
  updateBillLine,
  deleteBillLine
} from '../../services/requests/bills'
import LineAdd from './lines/LineAdd'
import CardHeader from '../misc/shared/CardHeader'

import LineHeaders from './mixins/lineHeaders'
import HeaderFilters from '../../services/mixins/headerFilters'
import BillLine from '@/services/models/billLine'
import Bill from '@/services/models/bill'
import FormatDates from '@/services/mixins/formatDates'

import LineView from './lines/LineView'
import DatePickerWrapper from '../misc/fields/DatePickerWrapper'
import { sumProp } from '../../utils/Utils'

export default {
  mixins: [FormatDates, LineHeaders, HeaderFilters],
  components: { CardHeader, DatePickerWrapper, LineView, LineAdd },
  props: {
    item: {
      type: Object,
      required: true
    },
    dialog: {
      type: Boolean,
      required: true
    },
    action: {
      type: Function,
      required: true
    },
    close: {
      type: Function,
      required: true
    },
    loading: {
      type: Boolean,
      required: true
    }
  },
  mounted() {
    this.getLines()
  },
  data() {
    return {
      lines: [],
      editedItem: Object.assign({}, this.item),
      statusItems: ['ENTERED', 'COMMITED', 'REVIEW', 'APPROVED', 'CLOSED'],
      typeItems: ['INVOICE', 'CREDIT', 'EXPORT INVOICE'],
      loadingLines: false,
      addLineDialog: false
    }
  },
  computed: {
    lineHeaders() {
      return Number(this.window?.width) >= 600
        ? this.headers
        : this.mobileHeaders
    },
    sellTotalForLines() {
      return this.lines.length ? sumProp(this.lines, 'sellTotal') : 0
    },
    typeIcon() {
      return this.editedItem.type == 'CREDIT'
        ? 'mdi-file-minus'
        : 'mdi-file-plus'
    }
  },
  methods: {
    async getLines() {
      try {
        this.loadingLines = true
        const result = await getLinesByParentId(this.item.id)
        const items = result.data.result
        this.lines = items.map(item => new BillLine(item))
        this.loadingLines = false
      } catch (err) {
        console.log(err)
      }
    },
    async onEdit({ item }) {
      try {
        this.loadingLines = true
        const payload = {
          billId: item.billId,
          sellTotal: Number(item.sellTotal),
          description: item.description,
          notes: item.notes
        }
        const result = await updateBillLine(item.id, payload)
        if (result) {
          const i = this.lines.findIndex(line => line.id === item.id)
          if (~i) {
            const editedLine = new BillLine(result.data.result)
            Vue.set(this.lines, i, editedLine)
            this.$store.commit(`bills/updateArrayStateById`, {
              prop: 'lines',
              editedValue: editedLine
            })
          }
          this.snack({
            text: `${this.titleSingular} line updated`,
            color: 'green'
          })
        }
        this.loadingLines = false
        return result
      } catch (err) {
        this.loadingLines = false
        console.log(err)
      }

      // return updateBillLine(item.id, payload)
      //   .then(result => {
      //     const i = this.lines.findIndex(line => line.id === item.id)
      //     if (~i) {
      //       const editedLine = new BillLine(result.data.result)
      //       Vue.set(this.lines, i, editedLine)
      //       this.$store.commit(`bills/updateArrayStateById`, {
      //         prop: 'lines',
      //         editedValue: editedLine
      //       })
      //     }
      //     this.loadingLines = false
      //     this.snack({
      //       text: `${this.titleSingular} line updated`,
      //       color: 'green'
      //     })
      //   })
      //   .catch(err => {
      //     this.loadingLines = false
      //     console.log(err)
      //   })
    },
    async addLine(line) {
      try {
        this.loadingLines = true
        const result = await addBillLine(line)
        if (result) {
          await this.getLines()
          const newLine = new BillLine(result.data.line)
          this.$store.commit('bills/addToArrayState', {
            prop: 'lines',
            value: newLine
          })
          this.$store.commit('bills/updateArrayStateById', {
            prop: 'bills',
            editedValue: new Bill(result.data.result)
          })
          this.snack({
            text: `${this.titleSingular} line created`,
            color: 'green'
          })
        }
        this.loadingLines = false
        return result
      } catch (err) {
        this.loadingLines = false
        console.log(err)
      }

      // return addBillLine(line)
      //   .then(async result => {
      //     await this.getLines()
      //     const newLine = new BillLine(result.data.line)
      //     this.$store.commit('bills/addToArrayState', {
      //       prop: 'lines',
      //       value: newLine
      //     })
      //     this.$store.commit('bills/updateArrayStateById', {
      //       prop: 'bills',
      //       editedValue: result.data.result
      //     })
      //     this.loadingLines = false
      //     this.snack({
      //       text: `${this.titleSingular} line created`,
      //       color: 'green'
      //     })
      //   })
      //   .catch(err => {
      //     this.loadingLines = false
      //     console.log(err)
      //   })
    },
    async onDelete(line) {
      try {
        this.loadingLines = true
        const result = await deleteBillLine(line.id)
        if (result) {
          this.$store.commit('bills/deleteFromArrayStateById', {
            prop: 'lines',
            id: line.id
          })
          this.$store.commit('bills/updateArrayStateById', {
            prop: 'bills',
            editedValue: new Bill(result.data.result)
          })
          await this.getLines()
          this.snack({
            text: `${this.titleSingular} line deleted`,
            color: 'yellow'
          })
        }
        this.loadingLines = false
        return result
      } catch (err) {
        this.loadingLines = false
        console.log(err)
      }

      // return deleteBillLine(line.id)
      //   .then(async result => {
      //     this.$store.commit('bills/deleteFromArrayStateById', {
      //       prop: 'lines',
      //       id: line.id
      //     })
      //     this.$store.commit('bills/updateArrayStateById', {
      //       prop: 'bills',
      //       editedValue: result.data.result
      //     })
      //     await this.getLines()
      //     this.loadingLines = false
      //     this.snack({
      //       text: `${this.titleSingular} line deleted`,
      //       color: 'green'
      //     })
      //   })
      //   .catch(err => {
      //     this.loadingLines = false
      //     console.log(err)
      //   })
    },
    async submit() {
      if (!this.$refs.form.validate()) {
        this.snackFormError()
        return
      }
      await this.action(this.editedItem)
      this.close()
    },
    cancel() {
      this.close()
      this.clear()
    },
    clear() {
      this.name = null
    }
  }
}
</script>
