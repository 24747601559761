<template>
  <v-card>
    <v-toolbar dark color="primary">
      <v-btn icon dark @click="close">
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-toolbar-title>
        <span>
          Edit {{ titleSingular }}
          {{ editedItem.type == 'CREDIT' ? ' (CREDIT) ' : '' }}-
          <span class="pl-1 subtitle-1">
            ID: <span class="font-weight-bold">{{ item.id }}</span>
          </span>
        </span>
      </v-toolbar-title>
    </v-toolbar>
    <v-container fluid>
      <v-row>
        <v-col cols="12" class="pb-0">
          <v-sheet
            outlined
            :color="editedItem.type == 'CREDIT' ? 'red' : ''"
            rounded
          >
            <v-card flat class="card-outlined">
              <v-form ref="editForm" @submit.prevent="edit()">
                <v-card-text class="pb-0">
                  <v-row>
                    <v-col cols="12" sm="6" md="3">
                      <v-autocomplete
                        :label="`* Billing Period`"
                        prepend-icon="mdi-calendar-blank"
                        v-model="editedItem.billingPeriod"
                        :items="$store.state.billingPeriods.allBillingPeriods"
                        item-value="id"
                        :rules="billingPeriodRules"
                        :disabled="editedItem.closed"
                      ></v-autocomplete>
                    </v-col>
                    <v-col
                      v-if="editedItem.type == 'CREDIT'"
                      cols="12"
                      sm="6"
                      md="3"
                      class="d-flex align-center"
                    >
                      <v-checkbox
                        disabled
                        label="Adjust RCTI"
                        hide-details
                        class="mt-0 mr-4"
                        v-model="editedItem.adjustRcti"
                      ></v-checkbox>
                      <v-checkbox
                        disabled
                        label="Adjust Bill"
                        hide-details
                        class="mt-0"
                        v-model="editedItem.adjustBill"
                      ></v-checkbox>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" sm="6" md="3">
                      <v-autocomplete
                        :label="
                          `* ${$store.state.global.pages.source.titleSingular}`
                        "
                        :prepend-icon="$store.state.global.pages.source.icon"
                        v-model="editedItem.sourceId"
                        :items="sources.filter(i => i.project.active)"
                        item-value="id"
                        item-text="sourceName_projectName"
                        clearable
                        :disabled="editedItem.closed"
                        :rules="sourceRules"
                      ></v-autocomplete>
                    </v-col>
                    <v-col cols="12" sm="6" md="3">
                      <v-text-field
                        label="External Docket #"
                        :prepend-icon="$store.state.global.pages.docket.icon"
                        v-model="editedItem.externalRef"
                        :rules="externalRefRules"
                        :disabled="editedItem.closed"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="3">
                      <DatePickerWrapper
                        label="* Date"
                        v-model="editedItem.date"
                        :rules="dateRules"
                        :disabled="editedItem.closed"
                      />
                    </v-col>
                    <v-col cols="12" sm="6" md="3">
                      <DatePickerWrapper
                        label="Date Received"
                        v-model="editedItem.dateReceived"
                        :rules="dateRules"
                        :disabled="editedItem.closed"
                      />
                    </v-col>
                    <v-col cols="12" sm="6" md="3">
                      <v-autocomplete
                        :label="
                          `* ${$store.state.global.pages.vehicle.titleSingular}`
                        "
                        :prepend-icon="$store.state.global.pages.vehicle.icon"
                        v-model="editedItem.vehicleId"
                        :items="
                          filteredVehicles.filter(
                            i => i.contractor.active && i.active
                          )
                        "
                        item-value="id"
                        item-text="vehicleName_contractorName_vehicleType"
                        clearable
                        :rules="vehicleRules"
                        :disabled="editedItem.closed"
                      ></v-autocomplete>
                    </v-col>
                    <v-col cols="12" sm="6" md="3">
                      <v-autocomplete
                        :label="
                          `* ${$store.state.global.pages.driver.titleSingular}`
                        "
                        :prepend-icon="$store.state.global.pages.driver.icon"
                        v-model="editedItem.driverId"
                        :items="
                          filteredDrivers.filter(
                            i => i.contractor.active && i.active
                          )
                        "
                        item-value="id"
                        item-text="driverName_contractorName"
                        clearable
                        :rules="driverRules"
                        :disabled="editedItem.closed"
                      ></v-autocomplete>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-text-field
                        label="Notes"
                        prepend-icon="mdi-note"
                        v-model="editedItem.notes"
                        :rules="notesRules"
                        :disabled="editedItem.closed"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn text color="grey darken-1" @click="close"
                    >Cancel</v-btn
                  >
                  <v-btn
                    text
                    color="primary"
                    :loading="loading"
                    type="submit"
                    :disabled="editedItem.closed"
                  >
                    Save
                  </v-btn>
                </v-card-actions>
              </v-form>
            </v-card>
          </v-sheet>
          <v-col cols="12" class="px-0">
            <DocketLineAdd
              :lines="docketLines"
              :date="editedItem.date"
              :isDay="editedItem.isDay"
              :vehicleId="editedItem.vehicleId"
              :sourceId="editedItem.sourceId"
              :onAdd="addDocketLine"
              :onEdit="onEdit"
              :onDelete="onDelete"
              :loading="loading"
              :loadingLines="loadingLines"
              :sellTotalForLines="sellTotalForLines"
              isEditDocket
              hideDetails
              :source="source"
              :showBilling="editedItem.source.project.billType === 'APPROVED'"
              :type="editedItem.type"
              :vehicle="vehicle"
            />
          </v-col>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import Vue from 'vue'
import Store from '../../store/computed/storeHelpers'
import {
  getLinesByDocketId,
  updateDocketLine,
  addDocketLine,
  deleteDocketLine
} from '../../services/requests/dockets'
import getWindowSize from '../../services/mixins/getWindowSize'
import DocketValidation from '@/services/validation/docket'
import FilterByContractor from '../../services/mixins/filterByContractor'
import LineHeaders from './lines/mixins/lineHeaders'
import Docket from '@/services/models/docket'
import DocketLine from '@/services/models/docketLine'
import { sumProp } from '../../utils/Utils'
import DatePickerWrapper from '../misc/fields/DatePickerWrapper'
import DocketLineAdd from './lines/DocketLineAdd'
import 'vue-datetime/dist/vue-datetime.min.css'

export default {
  mixins: [DocketValidation, FilterByContractor, LineHeaders, getWindowSize],
  components: {
    DatePickerWrapper,
    DocketLineAdd
  },
  props: {
    dialog: {
      type: Boolean,
      required: true
    },
    item: {
      type: Object,
      required: true
    },
    action: {
      type: Function,
      required: true
    },
    close: {
      type: Function,
      required: true
    },
    loading: {
      type: Boolean,
      required: true
    }
  },
  mounted() {
    this.getLines()
  },
  data() {
    return {
      editedItem: Object.assign({}, this.item),
      docketLines: [],
      loadingLines: false,
      addLineDialog: false
    }
  },
  computed: {
    showSell() {
      return this.$store.state.user.userAuthClass.showSell
    },
    sources: Store.getSet({ store: 'sources', prop: 'sourcesLite' }),
    vehicles: Store.getSet({ store: 'vehicles', prop: 'vehiclesLite' }),
    drivers: Store.getSet({ store: 'drivers', prop: 'driversLite' }),
    sellTotalForLines() {
      return this.docketLines.length
        ? sumProp(this.docketLines, 'sellTotal')
        : 0
    }
  },
  watch: {
    editedItem: {
      deep: true,
      handler() {
        if (this.editedItem.sourceId != this.docketLines[0]?.docket.sourceId)
          console.log('Changing Source may effect pricing')
        if (this.editedItem.vehicleId != this.docketLines[0]?.docket.vehicleId)
          console.log('Changing Vehicle may effect pricing')
      }
    }
  },
  methods: {
    async getLines() {
      try {
        this.loadingLines = true
        const result = await getLinesByDocketId(this.item.id)
        const items = result.data.result.lines
        this.editedItem = new Docket(result.data.result.docket)
        this.docketLines = items.map(item => new DocketLine(item))
        this.loadingLines = false
      } catch (err) {
        console.log(err)
      }
    },
    async edit() {
      if (!this.$refs.editForm.validate()) {
        this.snackFormError()
        return
      }
      await this.action(this.editedItem)
      this.close()
    },
    async onEdit({ item }) {
      try {
        this.loadingLines = true
        const sellTotal = item.sellPrice * item.qty
        const outstandingBillingAmount =
          sellTotal - item.approvedBillingAmount - item.waivedAmount
        const payload = {
          docketId: item.docketId,
          lineNumber: item.lineNumber,
          destinationId: item.destinationId,
          itemTypeId: item.itemTypeId,
          qty: item.qty,
          sellUnit: item.sellUnit,
          sellPrice: item.sellPrice,
          costQty: item.costQty,
          costUnit: item.costUnit,
          costPrice: item.costPrice,
          destinationWeight: item.destinationWeight,
          sourceWeight: item.sourceWeight,
          vehicleWeight: item.vehicleWeight,
          start: item.start ? new Date(`${item.start}`) : item.start,
          end: item.end ? new Date(`${item.end}`) : item.end,
          breaks: item.breaks,
          travel: item.travel,
          hours: item.hours,
          wbDocketNumber: item.wbDocketNumber,
          useAltRate: item.useAltRate,
          useNightRate: item.useNightRate,
          useOverrideCost: item.useOverrideCost,
          approvedBillingAmount: item.approvedBillingAmount,
          outstandingBillingAmount: outstandingBillingAmount.toFixed(4),
          waivedAmount: Number(item.waivedAmount).toFixed(4),
          rateId: item.rate?.id,
          notes: item.notes,
          GPSConfirmed: item.GPSConfirmed,
          GPSNotes: item.GPSNotes,
          tippingDocketConfirmed: item.tippingDocketConfirmed,
          tippingDocketNotes: item.tippingDocketNotes,
          massDeclarationConfirmed: item.massDeclarationConfirmed,
          massDeclarationNotes: item.massDeclarationNotes
        }
        const result = await updateDocketLine(item.id, payload)
        if (result) {
          const i = this.docketLines.findIndex(line => line.id === item.id)
          if (~i)
            Vue.set(this.docketLines, i, new DocketLine(result.data.result))
          this.$store.commit('dockets/updateArrayStateById', {
            prop: 'lines',
            editedValue: new DocketLine(result.data.result)
          })
          this.snack({
            text: `${this.titleSingular} line updated`,
            color: 'green'
          })
        }
        this.loadingLines = false
        return result
      } catch (err) {
        this.loadingLines = false
        console.log(err)
      }
    },
    async addDocketLine(line) {
      try {
        this.loadingLines = true
        const { id, rate, ...data } = line
        console.log(id, rate)
        const payload = {
          ...data,
          docketId: this.editedItem.id
        }
        const result = await addDocketLine(payload)
        if (result) {
          await this.getLines()
          const newDocketLine = new DocketLine(result.data.result)
          this.$store.commit('dockets/addToArrayState', {
            prop: 'lines',
            value: newDocketLine
          })
          this.$store.commit('dockets/updateArrayStateById', {
            prop: 'dockets',
            editedValue: result.data.result
          })
          this.snack({
            text: `${this.titleSingular} line created`,
            color: 'green'
          })
        }
        this.loadingLines = false
        return result
      } catch (err) {
        this.loadingLines = false
        console.log(err)
      }
    },
    async onDelete(line) {
      try {
        this.loadingLines = true
        const result = await deleteDocketLine(line.id)
        if (result) {
          if (result.data.result) {
            this.$store.commit('dockets/updateArrayStateById', {
              prop: 'dockets',
              editedValue: result.data.result
            })
            await this.getLines()
            this.loadingLines = false
            this.snack({
              text: `${this.titleSingular} line deleted`,
              color: 'yellow'
            })
          } else {
            this.$store.commit('dockets/deleteFromArrayStateByValue', {
              prop: 'dockets',
              value: this.item
            })
            this.close()
            this.loadingLines = false
            this.snack({
              text: `${this.titleSingular} line deleted`,
              color: 'yellow'
            })
          }
        }
        this.loadingLines = false
        return result
      } catch (err) {
        this.loadingLines = false
        console.log(err)
      }
    }
  }
}
</script>

<style>
.theme-datepicker {
  border-style: solid;
  border-color: rgb(177, 177, 177);
  border-width: 1px;
  padding: 15px 15px 15px 15px;
}
</style>
